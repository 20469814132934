<template>
  <div class="container">

    <!--Breadcrumb 영역 s-->
    <div class="top-nav-container">
      <nav role="navigator">
        <ul class="breadcrumbs list-unstyled hide-sm">
          <li class="breadcrumb">제품</li>
          <li class="breadcrumb" v-html="getHead" @click="$router.push(getLandingPath)" role="link"/>
          <li class="breadcrumb" v-html="getTitle" @click="$router.push(getLandingPath)" role="link"/>
          <li class="breadcrumb">질환정보</li>
        </ul>
      </nav>
    </div>
    <!--Breadcrumb 영역 e-->

    <div class="sectione">

      <!--Page Title 영역 s-->
      <div class="tit_page_area">
        <h1>질환정보</h1>
        <p>BMS 제품 유관 주요 질환정보 콘텐츠를 제공합니다.</p>
        <!--<div class="tit_right_icon_area">
            <a href="./product_brochure_listview.html">
                <img src="@/assets/images/icon_listview.png" alt="">
            </a>
        </div>-->
      </div>
      <!--Page Title 영역 e-->


      <!--본문 영역 s-->
      <content-area
          v-bind="board"
          @click="check($event)"
          file-name="BMSON-질환정보"
          :bookmark-id="$route.params.relatedDiseaseNo"/>
      <!--본문 영역 e-->


      <!--list view 버튼-->
      <div class="btn_area_right btn_listview">
        <router-link to="../" append>
          <button>List View</button>
        </router-link>
      </div>
      <div class="modal" v-if="showModal">
        <!-- 사이트 이탈 Modal의 내용 -->
        <div class="modal_contents">
          <div class="modal_close close">
            <a @click="closeModal">close</a>
          </div>
          <p class="modal_tit"><strong>BMSON</strong>웹사이트가 아닌 외부사이트로 이동합니다.</p>
          <p class="modal_exp">다른 웹사이트에의 링크. BMS가 관리하지 않는 제3자의 사이트는 이용자의 편의를 위해
            본 웹사이트에 링크되었으며, 이러한 링크가 BMS가 해당 제3자 사이트에 게재된 내용을
            권장하거나 지지하는 것을 의미하지 않습니다.  본 웹사이트 외의 웹사이트 및 링크된 웹사이트에
            대해서 BMS는 관리할 권한이 없으며, 해당 사이트에 게재된 내용에 책임을 지지 않습니다.
            다른 웹사이트로의 연결은 이용자가 그 위험을 부담합니다. 다른 웹사이트에 접속한 경우,
            새로운 웹사이트의 법적 고지 및 개인 정보 정책을 반드시 검토하시기 바랍니다.
            이는 본 웹사이트를 규율하는 법적 고지 및 개인 정보 정책과 다를 수 있습니다.</p>
          <div class="modal_btn">
            <span class="btn_modal" @click="closeModal({url: url})">계속하기</span>
            <span class="btn_modal" @click="closeModal">취소</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ContentArea from "@/components/common/ContentArea";

export default {
  name: "RelatedDiseaseView",
  components: {ContentArea},
  created() {
    this.initBoard();
    this.$emit('set-init-tab')
  },
  data() {
    return {
      board: {
        title: ''
      },
      url: '',
      showModal: false,
    }
  },
  methods: {
    check(event) {
      let {target} = event
      this.findATag(target, event)
    },
    findATag(element, event) {
      if(!element.parentNode) {
        event.preventDefault()
        return false;
      } else if(element.tagName.toLowerCase() !== 'a'){
        return this.findATag(element.parentNode, event)
      } else {
        let {href, origin: targetOrigin} = element
        let {origin: locationOrigin} = location
        if (href) {
          let expUrl = /^http[s]?:\/\//i;
          if (expUrl.test(href)) {
            if (targetOrigin !== locationOrigin) {
              this.openExternalModal(element.href)
            } else {
              return true;
            }
          }
        }
      }
      event.preventDefault()
    },
    openExternalModal(url) {
      this.url = url
      this.showModal = true;
      document.documentElement.classList.add("overflow-y-hidden");
    },
    closeModal({url}) {
      if (url) {
        window.open(url, '_blank')
      }
      document.documentElement.classList.remove("overflow-y-hidden");
      this.showModal = false;
    },
    initBoard() {
      this.$store.dispatch('initBoard', {
        id: this.$route.params.relatedDiseaseNo,
        type: 'related-disease'
      }).then(board => {
        this.board = board
      })
    }
  },
  props: {},
  computed: {
    getLandingPath() {
      return {
        name: 'Product',
        params: {productName: this.$route.params.productName},
      }
    },
    getHead() {
      return this.$store.state.menu.productMenu[this.$route.params.productName].head
    },
    getTitle() {
      return this.$store.state.menu.productMenu[this.$route.params.productName].title
    },
  },
}
</script>

<style scoped>

</style>
